import React from "react";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";
import Layout from "../../components/layout";

const Drake = () => {
  return (
    <Layout>
      <Head title="Drake" />
      <h3>Drake / OVO Sound Radio</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Toronto, Canada
      </h4>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/tDNB0ILnqPU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </Layout>
  );
};

export default Drake;
